/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Modal from 'packs/common/Modal';
import ChallengeDetails from 'packs/common/ChallengeDetails';

import Question from './Question';
import FormDescription from './FormDescription';

export default class FormResponseSection extends React.Component {
  constructor(props) {
    super(props);
    this.state = { active: props.active };
  }

  toggleContent = () => {
    this.setState(prevState => ({
      active: !prevState.active,
    }));
  }

  isHeaderPresent() {
    const { icon, title } = this.props;
    return icon || title || this.isScorePresent() || this.isRecommendationPresent();
  }

  isScorePresent() {
    return this.props.score && this.props.score.max_score > 0;
  }

  isRecommendationPresent() {
    return this.props.score && this.props.score.recommendation &&
      (this.props.score.recommendation.text !== '' ||
        this.props.score.recommendation.challenges.length > 0 ||
        this.props.score.recommendation.posts.length > 0);
  }

  isRecommendedChallengesPresent() {
    return this.isRecommendationPresent() && this.props.score.recommendation.challenges.length > 0;
  }

  isRecommendedResourcesPresent() {
    return this.isRecommendationPresent() && this.props.score.recommendation.posts.length > 0;
  }

  isPriority() {
    return this.isScorePresent() && this.props.score.priority;
  }

  renderIcon() {
    return (
      <span
        className="align-self-center me-2 title-image-icon"
        style={{
          backgroundColor: this.props.color,
          width: '30px',
          height: '30px',
          padding: '2px',
          borderRadius: '4px',
        }}
      >
        <img
          src={this.props.icon}
          alt=""
          style={{ maxWidth: '100%' }}
        />
      </span>
    );
  }

  render() {
    return (
      <div className="lh-card" style={{ borderColor: this.props.color }}>
        {this.isPriority() && (
          <div className="label-ribbon-stacked m-0">
            <div className="label-ribbon label-ribbon--danger" title="This section's recommendations are a priority for you.">
              <span>Priority</span>
            </div>
          </div>
        )}
        {this.isHeaderPresent() && (
          <div className="lh-card-header">
            <div className="form-section-progress">
              <div className="form-section-progress__col-large">
                <div className="d-flex">
                  <div className="">
                    {this.props.icon && this.renderIcon()}
                  </div>
                  {this.props.title && (
                    <div className="flex-grow-1">
                      <small className="text-muted"><span>Section</span></small>
                      <h3><span>{this.props.title}</span></h3>
                    </div>
                  )}
                </div>
                {this.isScorePresent() && (
                  <div className="progress m-0">
                    <div className="progress-bar" role="progressbar" aria-valuenow={this.props.score.percent} aria-valuemin="0" aria-valuemax="100" style={{ backgroundColor: this.props.color, width: `${this.props.score.percent}%` }}>
                      <span className="visually-hidden" dangerouslySetInnerHTML={{ __html: `<span>${this.props.score.percent}%</span>` }} />
                    </div>
                  </div>
                )}
              </div>
              {this.isRecommendationPresent() && (
                <div className="form-section-progress__col-small">
                  <div className="progress-symbol">
                    <img className="progress-symbol__image" src={this.props.score.recommendation.icon} width={60} height={60} alt="" />
                    <span className="progress-symbol__label"><span>{this.props.score.recommendation.title}</span></span>
                  </div>
                </div>
              )}
            </div>
            {this.isRecommendationPresent() && (
              <div>
                <h3><span>Recommendation:</span></h3>
                <div dangerouslySetInnerHTML={{ __html: `<span>${this.props.score.recommendation.text}</span>` }} />
              </div>
            )}
          </div>
        )}
        <div className={classNames('lh-card-content', { 'd-block': this.state.active, 'd-none': !this.state.active })}>
          {this.props.description && (
            <FormDescription
              text={this.props.description}
              color={this.props.color}
            />
          )}
          {this.props.questions.map(question => (
            <fieldset className="disabled" key={question.id}>
              <Question
                {...question}
                type={question.kind}
                name={`question_${question.id}`}
                value={this.props.answers[question.id]}
                disabled
                onChange={() => false}
              />
            </fieldset>
          ))}
        </div>
        <div className="lh-card-footer">
          {this.isRecommendedChallengesPresent() && (
            <div className="clearfix margin-bottom-sm border-bottom">
              <h6 className="text-muted margin-bottom-sm"><span>Recommended Challenges:</span></h6>
              {this.props.score.recommendation.challenges.map((challenge) => {
                const trigger = (
                  <a
                    href={challenge.url}
                    className="btn btn-default text-secondary float-start margin-right-sm margin-bottom-sm"
                  >
                    <span className={`fa-stack text-${challenge.category}`}>
                      <i className="fa fa-square fa-stack-2x" />
                      <i className={`po8 po8-${challenge.category} fa-stack-1x fa-inverse`} />
                    </span>
                    <span>&nbsp;{challenge.name}&nbsp;</span>
                    <i className="fa fa-angle-right" />
                  </a>
                );
                return (
                  <Modal key={challenge.id} trigger={trigger}>
                    <ChallengeDetails {...challenge} />
                  </Modal>
                );
              })}
            </div>
          )}
          {this.isRecommendedResourcesPresent() && (
            <div className="clearfix margin-bottom-sm border-bottom">
              <h6 className="text-muted margin-bottom-sm"><span>Recommended Resources:</span></h6>
              {this.props.score.recommendation.posts.map(post => (
                <a
                  key={post.id}
                  href={post.url}
                  className="btn btn-default text-secondary float-start margin-right-sm margin-bottom-sm"
                >
                  <span className="fa-stack text-other">
                    <i className="fa fa-square fa-stack-2x" />
                    <i className="fa fa-book fa-stack-1x fa-inverse" />
                  </span>
                  <span>&nbsp;{post.title}&nbsp;</span>
                  <i className="fa fa-angle-right" />
                </a>
              ))}
            </div>
          )}
          <div className="col-sm-7 col-center">
            <span
              role="button"
              tabIndex={this.state.active && 0}
              className="btn btn-default btn-block u-floaty"
              onClick={this.toggleContent}
            >
              <span>{this.state.active ? 'Hide' : 'View'} Details</span>
              <i role="presentation" className={classNames('ms-2', 'fa', { 'fa-angle-up': this.state.active, 'fa-angle-down': !this.state.active })} />
            </span>
          </div>
        </div>
      </div>
    );
  }
}

FormResponseSection.propTypes = {
  active: PropTypes.bool,
  color: PropTypes.string,
  icon: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  questions: PropTypes.array.isRequired,
  answers: PropTypes.object.isRequired,
  score: PropTypes.object.isRequired,
};

FormResponseSection.defaultProps = {
  color: '',
  icon: '',
  title: '',
  description: undefined,
  active: false,
};
