import React from 'react';
import ReactDOM from 'react-dom';

type ConditionCallback = () => boolean;

const defaultCallback: ConditionCallback = () => true;

function renderComponent<T extends React.ComponentClass>(
  Component: T,
  name: string,
  condition: ConditionCallback = defaultCallback,
): void {
  if (!condition()) return;

  document.querySelectorAll(`[data-react="${name}"]`)
    .forEach((el) => {
      const props = JSON.parse(el.getAttribute('data-props') || '');
      ReactDOM.render(<Component {...props} />, el);
    });
}
export default renderComponent;
